import React, {useState} from 'react';
import { motion } from 'framer-motion';
import { FaPython, FaReact, FaNodeJs, FaFigma, FaGithub, FaCss3Alt, FaHtml5, FaJs, FaGitAlt, FaInstagram, FaLinkedin, FaWhatsapp, FaGitlab, FaHome, FaUser, FaProjectDiagram, FaCertificate, FaPhone, FaBars } from 'react-icons/fa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SiGoogledrive } from 'react-icons/si';
import { ExternalLinkIcon, DownloadIcon } from 'lucide-react';



function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    setMenuOpen(false); // Close menu after clicking
  };

  const skills = [
    { icon: <FaPython />, name: 'Python' },
    { icon: <FaReact />, name: 'React' },
    { icon: <FaNodeJs />, name: 'Node.js' },
    { icon: <FaHtml5 />, name: 'HTML5' },
    { icon: <FaCss3Alt />, name: 'CSS3' },
    { icon: <FaJs />, name: 'JavaScript' },
    { icon: <FaFigma />, name: 'Figma' },
    { icon: <FaGitAlt />, name: 'Git' }
  ];

  const menuItems = [
    { id: 'home', icon: <FaHome />, label: 'Home' },
    { id: 'about', icon: <FaUser />, label: 'About' },
    { id: 'projects', icon: <FaProjectDiagram />, label: 'Projects' },
    { id: 'certificates', icon: <FaCertificate />, label: 'Certificates' },
    { id: 'contact', icon: <FaPhone />, label: 'Contact' }
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true
  };

  return (
  <div className='min-h-screen bg-black text-white font-sans'>
  <nav className='p-4 bg-black border-b-2 border-white flex justify-between items-center md:px-8 relative'>
        <div className='md:hidden z-20'>
          <button onClick={toggleMenu} className='text-white text-2xl'>
            <FaBars />
          </button>
        </div>
        <div className={`absolute top-14 left-0 w-full bg-black border-t border-white md:static md:border-none flex justify-center md:space-x-4 lg:space-x-6 transition-all duration-300 z-10 ${menuOpen ? 'flex flex-col items-center' : 'hidden'} md:flex md:flex-row`}> 
          {menuItems.map((item, idx) => (
            <button 
              key={idx} 
              onClick={() => scrollToSection(item.id)} 
              className='block md:inline-block p-2 md:p-0 w-full md:w-auto text-center text-white hover:text-gray-400'>
              <span className='md:hidden'>{item.icon}</span>
              <span className='hidden md:inline'>{item.label}</span>
            </button>
          ))}
        </div>
      </nav>


      <section id='home' className='h-screen flex items-center justify-center bg-black text-white relative overflow-hidden px-4'>
        <motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 1, ease: 'easeOut' }} className='flex flex-col items-center z-10'>
          <img src='img/me.jpg' alt='Profile' className='w-60 h-60 md:w-52 md:h-52 rounded-full mb-4 border-4 border-white shadow-2xl object-cover' />
          <h2 className='text-5xl md:text-6xl font-bold mb-2 tracking-wide text-gray-100 text-center'>Hello, I'm Atthariq!</h2>
          <p className='mt-2 text-lg md:text-2xl text-gray-300 text-center'>Full-Stack Developer & UI/UX Designer</p>
        </motion.div>

        <motion.div className='absolute inset-0 z-0 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 opacity-20' initial={{ opacity: 0 }} animate={{ opacity: 0.2 }} transition={{ duration: 1.5 }} />
        <motion.div className='absolute inset-0 z-0 flex justify-center items-center' initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 0.1, scale: 1 }} transition={{ duration: 2, ease: 'easeOut' }}>
          <div className='w-96 h-96 bg-white rounded-full opacity-10 blur-3xl'></div>
        </motion.div>
      </section>

      <section id='about' className='h-screen flex flex-col items-center justify-center bg-gray-900 text-white'>
        <h2 className='text-3xl font-semibold mb-4'>About Me</h2>
        <p className='w-2/3 text-center mb-6'>I'm a passionate full-stack developer with expertise in React, Express.js, and UI/UX design. I love creating impactful and user-friendly applications.</p>
        <Slider {...sliderSettings} className='w-2/3'>
          {skills.map((skill, index) => (
            <div key={index} className='flex flex-col items-center'>
              <div className='text-4xl mb-2'>{skill.icon}</div>
              <p className='text-sm'>{skill.name}</p>
            </div>
          ))}
        </Slider>
      </section>

      <section id="projects" className="min-h-screen flex flex-col items-center justify-center bg-gray-800 text-white py-12">
        <h2 className="text-3xl font-semibold mb-8">My Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-4/5">
          {[
            { src: 'img/Perumahan-ilustrasi.jpeg', title: 'GFI', desc: 'Project yang dikembangkan untuk bisa memudahkan komunikasi antar tetangga di suatu komplek perumahan, berbasis android dengan menggunakan framework ionic', link: 'https://github.com/Atth01/GFH-ADMIN' },
            { src: 'img/Resto-Ilustrasi.jpg', title: 'WEB Rumah Makan', desc: 'Merupakan aplikasi yang dibangun untuk restoran yang bertujuan memudahkan dalam order dan sebagainya, fullstack, berbasis web dan android', link: 'https://github.com/Atth01/APP_WEB_KURING_RESTO' },
            { src: 'img/SSB-Ilustrasi.jpeg', title: 'Youth Tiger School', desc: 'Web yang dibuat untuk sistem informasi terkait dengan SSB Youth Tiger School Bogor, dibuat dengan menggunakan framework react dan tailwind css', link: 'https://github.com/Atth01/wst_uas_ytss' },
            { src: 'img/resize-Ilustrasi.jpg', title: 'Resize Foto Otomatis', desc: 'Program yang dibuat untuk bisa mengubah ukuran foto secara otomatis menggunakan bahasa pemograman python', link: 'https://github.com/Atth01/resize_dataset' },
            { src: 'img/maxim-ilustrasi.jpeg', title: 'Revamp Maxim', desc: 'Project final dari bootcamp myskill, merevamp tampilan UI, User Journey dan lain - lain dari aplikasi maxim', link: 'https://www.linkedin.com/in/atthariq-haykal-putera-508b98207/overlay/1739169502292/single-media-viewer/?profileId=ACoAADStxdgBbDNT5bQb1Yiplr7q4ewZNJ9kCVc' },
            { src: 'img/perpustakaan-ilustrasi.jpeg', title: 'Perpustakaan', desc: 'Project final dari bootcamp sanbercode, membuat website perpustakaan dengan menggunakan framework php laravel', link: 'https://gitlab.com/sanbercode5091836/sanbercode_last_project' },
          ].map((project, index) => (
            <motion.div
              key={index}
              className="bg-gray-900 p-4 rounded-lg shadow-lg"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <img
                src={project.src}
                alt={project.title}
                className="w-full h-40 object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-bold mb-2 text-center">{project.title}</h3>
              <p className="text-gray-400 mb-4 text-justify">{project.desc}</p>
              <a
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-center items-center text-blue-400 hover:text-blue-600 transition text-3xl"
              >
                {project.title === 'GFI' ? <FaGithub /> : 
                project.title === 'WEB Rumah Makan' ? <FaGithub /> : 
                project.title === 'Youth Tiger School' ? <FaGithub /> :
                project.title === 'Resize Foto Otomatis' ? <FaGithub /> :
                project.title === 'Revamp Maxim' ? <FaLinkedin /> :
                project.title === 'Perpustakaan' ? <FaGitlab /> :
                <FaGithub />}
              </a>

            </motion.div>
          ))}
        </div>
      </section>

      <section id="certificates" className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white py-12">
      <h2 className="text-3xl font-semibold mb-8">My Certificates</h2>
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-4/5"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        {[
          { src: 'img/UIUX.jpg', title: 'UI/UX Design Certification', link: 'https://example.com/cert1' },
          { src: 'img/Laravel.jpg', title: 'Laravel Web Development Certificate', link: 'https://example.com/cert2' },
          { src: 'img/Data Analysis.jpg', title: 'Data Analysis Certificate', link: 'https://example.com/cert3' }
        ].map((cert, index) => (
          <motion.div
            key={index}
            className="bg-gray-800 p-4 rounded-lg shadow-lg"
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
          >
            <img src={cert.src} alt={cert.title} className="w-full h-40 object-cover rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2 text-center">{cert.title}</h3>
            <a
              href={cert.link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center"
            >
              <ExternalLinkIcon className='w-5 h-5 text-blue-400 hover:text-blue-600 transition' />
            </a>
          </motion.div>
        ))}
      </motion.div>
    </section>


      <section id='contact' className='h-screen flex flex-col items-center justify-center bg-black text-white'>
        <h2 className='text-3xl font-semibold mb-4'>Contact Me</h2>
        <p className='w-2/3 text-center mb-6'>Feel free to reach out via email or connect on my social media. Let's create something amazing together!</p>
        <div className='flex space-x-6 text-4xl'>
          <a href='https://github.com/Atth01' target='_blank'><FaGithub className='hover:text-gray-400' /></a>
          <a href='https://www.linkedin.com/in/atthariq-haykal-putera-508b98207/' target='_blank'><FaLinkedin className='hover:text-gray-400' /></a>
          <a href='https://www.instagram.com/ahyka.l/' target='_blank'><FaInstagram className='hover:text-gray-400' /></a>
          <a href='https://wa.me/6289605473512' target='_blank'><FaWhatsapp className='hover:text-gray-400' /></a>
        </div>
      </section>

      <a
        href="/files/CV_YourName.pdf"
        download
        className="fixed bottom-5 right-7 bg-grey-400 text-white p-4 w-20 rounded-full shadow-lg hover:bg-blue-700 transition-all flex flex-col items-center justify-center space-y-1"
      >
        <DownloadIcon className="w-6 h-6 mb-1" />
        <span className="text-xs text-white mt-1">CV</span>
      </a>

      <footer className='bg-gray-800 text-white py-4 text-center'>
        <p>&copy; {new Date().getFullYear()} My Portfolio. All rights reserved. | Designed by Atthariq Haykal Putera</p>
      </footer>
    </div>
  );
}

export default App;
